type UnsubscribeResponse = {
  success: boolean;
  error: string;
};

export const unsubscribe = async (
  phone: string
): Promise<UnsubscribeResponse> => {
  const body = {
    Phone: phone,
  };

  const url = new URL(`api/Lead/Unsubscribe`, process.env.APP_API_URL).href;

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error(
      `Invalid response: ${response.status} - ${response.statusText}`
    );
  }

  const data = (await response.json()) as UnsubscribeResponse;

  if (data.error) {
    throw new Error(data.error);
  }

  return data;
};
