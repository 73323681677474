import React, { FunctionComponent } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import UnsubscribeForm from './UnsubscribeForm';

const AppRoutes: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/unsubscribe" element={<UnsubscribeForm />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
