import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FunctionComponent } from 'react';

const rotateAnimation = keyframes`
   100% {
    transform: rotate(360deg);
  }
`;

const dashAnimation = keyframes`
   0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;
const SvgSpinner = styled.svg`
  animation: ${rotateAnimation} 2s linear infinite;
  width: 50px;
  height: 50px;
`;

const SpinnerCircle = styled.circle`
  stroke: rgb(202, 101, 240);
  stroke-linecap: round;
  animation: ${dashAnimation} 1.5s ease-in-out infinite;
`;

const Spinner: FunctionComponent = () => (
  <SvgSpinner viewBox="0 0 50 50">
    <SpinnerCircle
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
    ></SpinnerCircle>
  </SvgSpinner>
);

export default Spinner;
