import styled from '@emotion/styled';
import React from 'react';

import LogoImg from '../assets/logo.png';

const Container = styled.header`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background: #f4f4f9;
  color: #49575e;
`;

const Logo = styled.div`
  text-align: center;
`;

const Header = () => {
  return (
    <>
      <Container>
        <Logo>
          <img src={LogoImg} />
        </Logo>
        <h4 style={{ marginRight: 15 }}>פתרונות פשוטים לעסק שלך</h4>
      </Container>
    </>
  );
};

export default Header;
