import styled from '@emotion/styled';
import React, {
  FormEventHandler,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import Header from './components/Header';
import Spinner from './components/Spinner';
import { unsubscribe } from './services/kavanuApi';
import isMobilePhone from './util/isMobilePhone';

const Container = styled.div`
  position: fixed;
  inset: 0;
  margin: 0;
  background: #efefef;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  font-family: 'Heebo';

  @media only screen and (min-width: 600px) {
    width: 500px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
`;

const ScreenContainer = styled.div`
  box-sizing: border-box;
  overflow-y: auto;
  padding-bottom: 50px;
  flex: 1;
  padding: 0 10px 10px 10px;
`;

const SubmitButton = styled.button`
  display: block;
  margin: 10px auto;
  padding: 8px 22px;
  background: ${(props) =>
    props.disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgb(202, 101, 240)'};
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  border: 0;
  border-radius: 4px;
  color: white;
  ${({ disabled }) => !disabled && `cursor: pointer;`}
`;

const ErrorText = styled.span`
  color: red;
  font-weight: 700;
`;

const InfoText = styled.span`
  font-weight: 700;
`;

const UnsubscribeForm: FunctionComponent = () => {
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resultText, setResultText] = useState('');
  const [errorText, setErrorText] = useState('');
  const isValidPhone = useMemo(() => isMobilePhone(phone), [phone]);

  const onSubmit = useCallback<FormEventHandler>(
    async (e) => {
      e.preventDefault();

      if (isLoading) return;

      setIsLoading(true);

      try {
        setResultText('');
        setErrorText('');
        await unsubscribe(phone);
        setResultText('הוסרת מרשימת התפוצה בהצלחה');
      } catch (err) {
        setErrorText(
          'לא הצלחנו להסירך מרשימת התפוצה, אנא נסה שנית מאוחר יותר או צור קשר'
        );
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, phone]
  );

  return (
    <Container>
      <Content>
        <Header />
        <ScreenContainer>
          <h2>עדכון הגדרות שיווק</h2>
          <span>בלחיצה על כפתור הסר, תוסר מרשימת התפוצה</span>
          <form style={{ marginTop: 10 }} onSubmit={onSubmit}>
            <label style={{ display: 'block' }}>
              <strong>מספר טלפון</strong>
              <input
                autoComplete="off"
                name="phone"
                type={'tel'}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                style={{ marginRight: 10 }}
              />
            </label>
            {isLoading && (
              <div style={{ margin: '20px 0', textAlign: 'center' }}>
                <Spinner />
              </div>
            )}
            <SubmitButton disabled={!isValidPhone} type="submit">
              הסר
            </SubmitButton>
          </form>
          {resultText && <InfoText>{resultText}</InfoText>}
          {errorText && <ErrorText>{errorText}</ErrorText>}
        </ScreenContainer>
      </Content>
    </Container>
  );
};

export default UnsubscribeForm;
